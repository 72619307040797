import { v2Colors, breakpoints, getRgbaColor, space } from '@web-for-marketing/react-ui';

export const navigationBarStyles = {
    mobileNavigationBar: {
        color: `${v2Colors.core.slate} !important`,
        position: 'sticky',
        backgroundColor: 'white !important',
        boxShadow: '0px 2px 2px rgba(1, 23, 41, 0.03) !important',
        zIndex: 300,
        [`@media (max-width: ${breakpoints.md})`]: {
            position: 'static',
        },
    },
    stickyNavbar: {
        position: 'sticky',
        top: 0,
        boxShadow: '0px 2px 2px rgba(1, 23, 41, 0.03) !important',
        zIndex: 400,
        backgroundColor: v2Colors.background.white,
        [`@media (max-width: ${breakpoints.md})`]: {
            position: 'static',
        },
    },
    header: {
        color: `${v2Colors.core.slate} !important`,
        boxShadow: 'none',
        position: 'relative',
        backgroundColor: v2Colors.background.white,
    },
    hamburgerMenuIcon: {
        height: '100%',
        width: '4.4rem',
        '& svg': {
            verticalAlign: 'middle',
        },
        '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
            height: '4.4rem !important',
        },
    },
    hamburgerButton: {
        padding: 0,
        marginRight: '2rem',
        border: `.2rem solid ${v2Colors.core.innovation}`,
        borderRadius: '0.6rem',
        '&:focus, &:hover': {
            backgroundColor: `${v2Colors.core.innovation} !important`,
            '& svg': {
                color: 'white !important',
            },
        },
    },
    hidden: {
        display: 'none !important',
    },
    verticalMargin: {
        width: '1rem',
        [`@media (max-width: ${breakpoints.lg})`]: {
            width: '1rem',
        },
    },
    menuToolbar: {
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        minHeight: '6rem !important',
        padding: '0 3.6rem',
        backgroundColor: 'white !important',
        position: 'relative',
        [`@media (max-width: ${breakpoints.md})`]: {
            height: '4.8rem !important',
            paddingLeft: space.containerOuterPadding,
            paddingRight: space.containerOuterPadding,
        },
    },
    mobileMenuToolbar: {
        zIndex: 400,
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        minHeight: '0 !important',
        position: 'relative',
    },
    logoSeparator: {
        borderLeft: `1px solid ${v2Colors.border.disabled}`,
        height: '2.4rem',
        margin: '0 2rem',
    },
    logo: {
        height: '100%',
        width: '18rem',
        '@media (max-width: 1159.9px)': {
            width: '12rem',
        },
    },
    logoImage: {
        maxWidth: '100%',
        height: '2.4rem',
        objectFit: 'contain',
    },
    logoImageLink: {
        width: 'fit-content',
    },
    mobileGeotabLogo: {
        display: 'block',
        height: '100%',
        maxWidth: '20rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            width: '17rem !important',
        },
        '@media (max-width: 374.9px)': {
            width: '100% !important',
        },
    },
    mobileInnerContainer: {
        padding: '0 3.6rem',
        height: '8rem',
        backgroundColor: `${v2Colors.background.white} !important`,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingLeft: space.containerOuterPadding,
            paddingRight: space.containerOuterPadding,
        },
    },
    classFocus: {
        height: '100%',
        position: 'relative',
        display: 'block',
        '&:focus': {
            outline: 'none',
            backgroundColor: 'rgba(1, 23, 41, 0.05) !important',
        },
        '&:hover::after': {
            height: 2,
        },
        '&:focus::after': {
            height: 2,
        },
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: 0,
            width: '100%',
            transition: 'height 300ms ease',
            backgroundColor: v2Colors.core.innovation,
            transform: 'translateY(1px)',
            zIndex: 100,
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            display: 'flex',
            '& svg': {
                position: 'static',
            },
            '&:hover::after': {
                height: 2,
            },
            '&:focus::after': {
                height: 2,
            },
        },
    },
    menuButton: {
        fontSize: '1.6rem !important',
        lineHeight: 1,
        padding: '1rem 1.5rem !important',
        minHeight: 0,
        marginRight: '0',
        width: 'auto',
        borderWidth: '2px !important',
        borderStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        zIndex: 1,
        whiteSpace: 'nowrap',
        [`@media (max-width: ${breakpoints.lg})`]: {
            padding: '0.75rem 1rem !important',
            fontSize: '1.4rem !important',
        },
    },
    linkButtonContainer: {
        height: '100%',
        margin: '0 0.3rem !important',
        width: 'auto',
        zIndex: 2,
        position: 'relative',
        boxSizing: 'border-box',
    },
    linkButton: {
        padding: '.8rem 1.2rem 3rem',
        display: 'flex',
        '& > *': {
            color: `${v2Colors.core.slate} !important`,
            marginLeft: '0.5rem',
        },
    },
    topMicroMenu: {
        height: '4rem',
        padding: '0 3.6rem',
        flexWrap: 'nowrap',
        alignItems: 'center',
        minHeight: '0 !important',
        backgroundColor: 'white !important',
        position: 'relative',
    },
    microMenuButtons: {
        color: v2Colors.core.slate,
        height: '100%',
        fontWeight: 400,
        display: 'flex',
        transform: 'none',
        margin: '0 0.7rem',
        minHeight: 'auto',
        borderRadius: '0.6rem',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        letterSpacing: 'normal',
        fontSize: '1.4rem !important',
        '&:hover': {
            backgroundColor: 'rgba(223, 228, 232, 0.4) !important',
        },
    },
    expansionPanels: {
        height: '100%',
        alignSelf: 'flex-end',
        alignItems: 'flex-start',
        paddingLeft: '2rem',
        flexWrap: 'nowrap',
        [`@media (max-width: ${breakpoints.md})`]: {
            display: 'none',
        },
    },
    menuButtonLeft: {
        marginLeft: '1rem',
    },
    menuButtonRight: {
        marginRight: '2rem',
    },
    GeotabContainer: {
        width: 'auto',
        '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
            height: '6rem !important',
            '& *': {
                height: '6rem !important',
            },
        },
    },
    requestWidthSeparator: {
        margin: '0.8rem 0 2rem',
        padding: '0 1rem',
        width: '3rem',
        zIndex: 0,
        [`@media (max-width: ${breakpoints.lg})`]: {
            width: '2rem',
        },
        '@media (max-width: 1159.9px)': {
            width: '1.6rem',
        },
    },
    popoutLogoAlignment: {
        height: '100%',
    },
    closePopoutButton: {
        color: v2Colors.core.slate,
        padding: '1rem',
        height: '1.6rem',
        width: '1.6rem',
        boxSizing: 'content-box',
        alignSelf: 'center',
        border: `0.1rem solid ${v2Colors.core.slate}`,
        '& > *': {
            strokeWidth: '0.1rem',
        },
    },
    spaceBetweenToolbar: {
        alignItems: 'stretch',
        justifyContent: 'space-between',
    },
    menuTitles: {
        padding: '0 1.2rem 3rem',
        margin: '0 0.3rem !important',
        alignItems: 'flex-end',
        height: '100%',
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '0 !important',
    },
    topMenuContainer: {
        [`@media (max-width: ${breakpoints.md})`]: {
            topMenuContainer: {
                display: 'none',
            },
        },
    },
    microLeftContentBar: {
        minHeight: '4rem',
        backgroundColor: v2Colors.background.dark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1.4rem 3.6rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingLeft: space.containerOuterPadding,
            paddingRight: space.containerOuterPadding,
        },
    },
    microLeftContentLink: {
        color: v2Colors.text.inversePrimary,
        textDecoration: 'underline',
        fontSize: '1.4rem',
        padding: '0.4rem',
        borderRadius: '3px',
        transition: 'background-color ease 500ms',
        '&:hover': {
            backgroundColor: getRgbaColor(v2Colors.background.darkest, 0.3),
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: getRgbaColor(v2Colors.background.darkest, 0.3),
        },
    },
    leftMarginIcon: { marginLeft: '1rem' },
    smallExternalIcon: {
        marginLeft: '0.5rem',
        width: '1.2rem',
        height: '1.2rem',
    },
} as const;
