import React, { useCallback, useMemo } from 'react';
import { navigationBarStyles as classes } from '../styleObjects/NavigationBar';
import { Toolbar, Grid } from '@mui/material';
import { MenuExpansion } from '../MenuExpansion';
import { VerticalSeparator } from '../../VerticalSeparator';
import { selectedLanguageAtom, remSizeAtom } from '@atoms/appSettings';
import { CMSLink } from '@components/CMSLink';
import { getLanguageUrlPrefixByCode } from '@helpers/languages';
import { GeotabWithR } from '@icon/GeotabWithR';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { NavbarMainContentItem } from '@models/navigationBar';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { NewTabIcon } from '@icon/NewTabIcon';
import { useAtom } from 'jotai';
import { navbarAtom } from '@atoms/navigation';
import { PublicSearchDropdown } from '@components/PublicSearch/PublicSearchDropdown';
import { Text } from '@web-for-marketing/react-ui';
import { ClassNames } from '@emotion/react';

interface MainMenuProps {
    userIsAuthenticated: boolean;
    selectedItem: string;
    setSelectedItem: (item: string) => void;
}

export function MainMenu({ userIsAuthenticated, selectedItem, setSelectedItem }: MainMenuProps): JSX.Element {
    const [remSize] = useAtom(remSizeAtom);
    const [navbar] = useAtom(navbarAtom);
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const { prefixStringWithBaseUrl } = useStaticValues();
    const renderNavigation = useMemo(
        () => navbar && navbar.version !== 2 && navbar.mainContent && navbar.mainContent.length,
        [navbar]
    );

    const onGeotabLogoClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>): void => {
        setSelectedItem('');
        event.currentTarget.blur();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMainMenuComponent = (component: NavbarMainContentItem): React.ReactNode => {
        switch (component.type) {
            case 'dropdown':
                return (
                    <Grid container css={{ marginTop: '0.8rem', width: 'auto' }}>
                        <MenuExpansion
                            setSelectedItem={setSelectedItem}
                            selectedItem={selectedItem}
                            name={component.content.title}
                            dropDownContent={component.content}
                        />
                    </Grid>
                );
            case 'link':
                return (
                    <Grid container alignItems='flex-end' css={classes.linkButtonContainer}>
                        <CMSLink
                            href={component.content.href}
                            underline='none'
                            css={[classes.classFocus, classes.linkButton]}
                            type='link'
                            target={component.content.isTargetToNewTab ? '_blank' : '_self'}
                        >
                            <Text color='tertiary' variant='body2'>
                                {component.content.title}{' '}
                            </Text>{' '}
                            {component.content.isTargetToNewTab ? <NewTabIcon aria-hidden='true' /> : null}
                        </CMSLink>
                    </Grid>
                );
            case 'button':
                return (
                    <CMSLink
                        type='button'
                        href={component.content.href}
                        variant={component.content.variant}
                        css={[classes.menuButton, classes.menuButtonLeft]}
                    >
                        {component.content.title}
                    </CMSLink>
                );
            case 'popout-button':
                return (
                    <PopoutButton
                        text={component.content.title}
                        variant={component.content.variant}
                        customSectionKey={component.content.customSection}
                        params={component.content.params}
                        displayMode={component.content.popoutDisplayMode}
                        backgroundImagePath={component.content.popoutBackgroundImage}
                        css={[classes.menuButton, classes.menuButtonLeft]}
                    />
                );
            default:
                break;
        }
    };

    return (
        <ClassNames>
            {({ css }) => (
                <Toolbar
                    disableGutters
                    classes={{
                        root: css(classes.menuToolbar),
                    }}
                >
                    <Grid container alignItems='flex-start' css={classes.GeotabContainer} wrap='nowrap'>
                        <div css={classes.logo}>
                            <CMSLink
                                href={
                                    !userIsAuthenticated
                                        ? getLanguageUrlPrefixByCode(selectedLanguage)
                                        : '/admin/dashboard/'
                                }
                                css={[classes.classFocus, classes.logoImageLink]}
                                onClick={onGeotabLogoClick}
                                aria-label='Go to Geotab Homepage'
                                type='link'
                                spaLink={userIsAuthenticated}
                            >
                                <GeotabWithR css={classes.logoImage} />
                            </CMSLink>
                        </div>
                        {navbar?.secondaryLogo ? (
                            <>
                                <div css={classes.logoSeparator} />
                                <div css={classes.logo}>
                                    {navbar.secondaryLogoLink ? (
                                        <CMSLink
                                            href={navbar.secondaryLogoLink}
                                            aria-label={navbar.secondaryLogoLabel || ''}
                                            css={[classes.classFocus, classes.logoImageLink]}
                                        >
                                            <img
                                                src={prefixStringWithBaseUrl(navbar.secondaryLogo)}
                                                alt={navbar.secondaryLogoAlt || ''}
                                                css={classes.logoImage}
                                            />
                                        </CMSLink>
                                    ) : (
                                        <img
                                            src={prefixStringWithBaseUrl(navbar.secondaryLogo)}
                                            alt={navbar.secondaryLogoAlt || ''}
                                            css={classes.logoImage}
                                        />
                                    )}
                                </div>
                            </>
                        ) : null}
                    </Grid>
                    {renderNavigation ? (
                        <Grid
                            container
                            alignItems='center'
                            direction='row'
                            justifyContent='flex-end'
                            css={classes.expansionPanels}
                            style={remSize > 10 ? { flexWrap: 'wrap' } : undefined}
                        >
                            {navbar && navbar.version !== 2
                                ? navbar.mainContent?.map((navigationItem: NavbarMainContentItem) => (
                                    <React.Fragment key={navigationItem.content.title}>
                                        {renderMainMenuComponent(navigationItem)}
                                    </React.Fragment>
                                ))
                                : null}
                            {navbar?.showSearch ? (
                                <>
                                    <VerticalSeparator css={classes.requestWidthSeparator} />
                                    <PublicSearchDropdown />
                                </>
                            ) : null}
                        </Grid>
                    ) : null}
                </Toolbar>
            )}
        </ClassNames>
    );
}
